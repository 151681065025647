<template>
   <!-- header -->
   <PrintViewHeader :includeQR="true" v-if="!isLoading"/>

   <!-- content -->
   <div class="pe-content" v-if="!isLoading">
      <!-- timeline -->
      <div class="pe-content__timeline-wrap">
         <div class="pe-info-item__title"> {{ $t("trip_timeline") }} </div>

         <div class="pe-content__timeline"> <TimeLine /> </div>
      </div>

      <!-- trip info -->
      <div class="pe-content__trip-info-wrap">
         <!-- dates -->
         <div class="pe-info-item is-date">
            <div class="pe-info-item__top">
               <span class="pe-info-item__icon icon__calendar"></span>
               <div class="pe-info-item__title"> {{ $t("trip_dates") }} </div>
            </div>

            <div class="pe-info-item__text">
               <span class="no-wrap">
                  {{ departureDay }}
                  <span class="h-font-bold"> {{ departureFullDate }} </span>
               </span>

               <span class="h-arrow"></span>
               <span class="no-wrap">
                  {{ returnDay }}
                  <span class="h-font-bold"> {{ returnFullDate }} </span>
               </span>
            </div>
         </div>

         <!-- pax -->
         <div class="pe-info-item">
            <div class="pe-info-item__top">
               <span class="pe-info-item__icon icon__profile"></span>

               <div class="pe-info-item__title"> {{ $t("participants") }} </div>

               <div class="pe-info-item__title-total"> {{ totalPax }} </div>
            </div>

            <div class="pe-info-item__text">
               <span v-html="adultsLabel"></span>
               {{ adultsFemale }}w - {{ adultsMale }}m
               <br>
               <span v-html="kidsLabel"></span>
               {{ kidsFemale }}w - {{ kidsMale }}m
            </div>
         </div>

         <!-- separator -->
         <div class="flex-grow-1"></div>

         <!-- how to get to -->
         <div class="pe-how-to-get-to" v-if="accommodationNotes">
            <div class="pe-how-to-get-to__top">
               <span class="pe-how-to-get-to__icon"></span>
               <div class="pe-how-to-get-to__top-text">
                  {{ $t("how_to_get_to") }}
                  <span class="pe-how-to-get-to__name"> {{ accomodationName }} </span>
               </div>
            </div>

            <div class="pe-how-to-get-to__text">
               <span v-html="accommodationNotes"></span>
            </div>
         </div>
      </div>
   </div>

   <!-- footer -->
   <div class="pe-footer" v-if="!isLoading">
      <div class="pe-footer__text">
         {{ $t('print_view_footer_text') }}
      </div>

      <div style="break-inside: avoid;">
         <div class="pe-footer__title"> {{ $t('db_offices') }} </div>

         <div class="pe-footer__text"> {{ $t('db_offices_text') }} </div>

         <div class="pe-footer__contact-item">
            <span class="pe-footer__contact-item-icon icon__phone-full"></span>
            <div class="pe-footer__contact-item-text">0049 (0)721 9385386 </div>
         </div>

         <div class="pe-footer__contact-item">
            <span class="pe-footer__contact-item-icon icon__mail-full"></span>
            <div class="pe-footer__contact-item-text">db-klassenfahrten@deutschebahn.com</div>
         </div>

         <div class="pe-footer__title"> {{ $t('db_emergency_services') }} </div>

         <div class="pe-footer__text"> {{ $t('db_emergency_services_text') }} </div>

         <div class="pe-footer__contact-item">
            <span class="pe-footer__contact-item-icon icon__phone-full"></span>
            <div class="pe-footer__contact-item-text">0049 (0)30 4606 4249</div>
         </div>
      </div>
   </div>
</template>

<script>
   import { mapState, mapGetters } from 'vuex';
   import stopLoadingOnMounted from '@/mixins/stopLoadingOnMounted.js';
   import TimeLine from '@/components/dashboard/timeline/TimeLine.vue';
   import PrintViewHeader from '@/components/shared/PrintViewHeader';

   export default {
      components: {
         TimeLine,
         PrintViewHeader,
      },
      mixins: [
         stopLoadingOnMounted,
      ],
      computed: {
         ...mapState({
            accomodationName: state => state.tripDetails.accommodations[0].title,
            accomodation: state => state.tripDetails.accommodations,
            adultsFemale: state => state.groupStructure ? state.groupStructure.adultsFemale : '',
            adultsMale: state => state.groupStructure ? state.groupStructure.adultsMale : '',
            departureDay: state => state.departureDate ? state.departureDate.weekDay : '',
            departureFullDate: state => state.departureDate ? state.departureDate.full : '',
            isGroupTrip: state => state.isGroupTrip,
            isLoading: state => state.loading.full,
            kidsFemale: state => state.groupStructure ? state.groupStructure.kidsFemale : '',
            kidsMale: state => state.groupStructure ? state.groupStructure.kidsMale : '',
            returnDay: state => state.returnDate ? state.returnDate.weekDay : '',
            returnFullDate: state => state.returnDate ? state.returnDate.full : '',
            totalPax: state => state.totalPax,
         }),
         ...mapGetters([
            'accommodationNotes',
         ]),
         adultsLabel() {
            return this.isGroupTrip ? this.$t('adults') : this.$t('teachers');
         },
         kidsLabel() {
            return this.isGroupTrip ? this.$t('kids') : this.$t('students');
         },
      },
      watch: {
         accomodation() {
            setTimeout(print, 500);
         }
      },
   }
</script>

<style lang="less">
   .p-print-view {
      background-color: white;

      .b-header,
      .b-side-menu,
      .b-footer {
         display: none;
      }

      .l-page {
         padding: 0;
      }

      .b-timeline {
         break-inside: avoid;
      }

      .pe-content {
         --column-margin: 20pt;

         background-color: var(--color-gray-light);
         padding: 20pt;
         position: relative;
         font-size: 12pt;
         overflow: hidden; // needed for fake backgrond for print
         .h-flex-row-left-top;

         &:before {
            // fake backgrond for print
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            overflow: hidden;
            z-index: 1;
            content: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10' style='background:%23ebece9' />");
         }

         &:after {
            // fake backgrond for print (need two to avoid white space on bottom when too many programs)
            position: absolute;
            right: 0px;
            bottom: 0px;
            left: 0;
            overflow: hidden;
            z-index: 1;
            content: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10' style='background:%23ebece9' />");
         }

         &__timeline-wrap {
            width: calc(~'60% -' var(--column-margin));
            z-index: 2; // needed for fake backgrond for print
         }

         &__trip-info-wrap {
            width: calc(~'40% -' var(--column-margin));
            z-index: 2; // needed for fake backgrond for print
         }

         &__timeline-wrap {
            margin-right: calc(~'var(--column-margin) * 2');
         }

         &__trip-info-wrap {
            .align-self(stretch);
            .h-flex-col;
         }

         &__timeline {
            width: 100%;

            .b-timeline__details-link {
               display: none;
            }
         }
      }

      .pe-info-item {
         margin-bottom: 40pt;

         &__title {
            text-transform: uppercase;
            color: var(--color-gray-2);
            font-size: 11pt;
            line-height: 1;
         }

         &__top {
            .h-flex-row-left-center;
         }

         &__icon {
            font-size: 14pt;
            color: var(--color-gray-2);
            line-height: 1;
            margin-right: 10pt;
         }

         &__title-total {
            margin-left: 5pt;
            line-height: 1;
            .h-font-bold;
         }

         &__text {
            margin-top: 5pt;
            font-size: 9pt;
         }

         &.is-date {
            .pe-info-item__text {
               font-size: 12pt;
            }
         }
      }

      .b-timeline-item {
         &.is-destination .b-timeline-item__dot {
            overflow: hidden; // needed for fake backgrond for print

            &:before {
               // fake backgrond for print
               position: absolute;
               top: -5px;
               right: -5px;
               bottom: -5px;
               left: -5px;
               overflow: hidden;
               z-index: 1;
               border-radius: 100%;
               content: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' style='background:black' />");
            }
         }

         &__line {
            width: 0px;
            border: solid black 1px;
         }
      }

      .pe-how-to-get-to {
         border: solid 1pt var(--color-gray-3);

         &__top {
            padding: 10pt;
            color: var(--color-gray-3);
            position: relative;
            .h-flex-row-left-top;

            >* {
               // needed for fake backgrond for print
               z-index: 2;
            }

            &:before {
               // fake backgrond for print
               position: absolute;
               top: 0;
               right: 0;
               bottom: 0;
               left: 0;
               overflow: hidden;
               z-index: 1;
               content: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10' style='background:black' />");
            }
         }

         &__icon {
            margin-right: 10pt;
            .icon(service-bell-full);

            &:before {
               color: white !important;
            }
         }

         &__top-text {
            line-height: 1;
            color: white !important;
         }

         &__name {
            color: white;
            text-indent: 8pt;
            color: white !important;
            .h-font-bold;
         }

         &__text {
            padding: 10pt;
            background-color: white;
            font-size: 9pt;
            position: relative;

            >* {
               // needed for fake backgrond for print
               z-index: 2;
               position: relative;
            }

            &:before {
               // fake backgrond for print
               position: absolute;
               top: 0;
               right: 0;
               bottom: 0;
               left: 0;
               overflow: hidden;
               z-index: 1;
               content: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10' style='background:white' />");
            }
         }
      }

      .pe-footer {
         padding-top: 20pt;

         &__text {
            font-size: 9pt;
            color: var(--color-gray-2);
         }

         &__title {
            font-size: 15pt;
            margin-top: 40pt;
            .h-font-black;
         }

         &__contact-item {
            line-height: 1;
            margin: 6pt 0;
            .h-flex-row-left-center;
         }

         &__contact-item-icon {
            font-size: 15pt;
            color: var(--color-gray-2);
            margin-right: 5pt;
         }

         &__contact-item-text {
            font-size: 13pt;
         }
      }
   }
</style>